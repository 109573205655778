import styled from "styled-components";
import AlserkalImage from "./../../assets/images/logo-alserkal-black.svg";
import AlserkalVenueImage from "./../../assets/images/venue-1.jpg";
import AlserkalStoryImage from "./../../assets/images/venue-2.jpg";

export const SearchResultsWrapper = styled.div`
  background-color: var(--white);
  padding: var(--outerMargin);
  position: relative;
  & > p,
  & > div {
    z-index: 2;
    mix-blend-mode: darken;
  }
  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100vw;
    right: -100vw;
    content: "";
    background-color: var(--white);
    z-index: 0;
  }
`;

export const ResultsBlock = styled.div`
  --gridGap: 23px;
  & > h3 {
    margin-top: 0;
    margin-bottom: 25px;
    color: var(--green);
  }
  & + div {
    margin-top: 25px;
    padding-top: 25px;
  }
  @media (max-width: 767px) {
    & h3 {
      text-align: center;
    }
  }
`;

export const ResultGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ResultCard = styled.div`
  display: flex;
  margin-right: 25px;
  border-bottom: 1px solid var(--green);
  padding-top: 25px;
  padding-bottom: 25px;
  &:first-of-type,
  &:nth-child(2),
  &:nth-child(3) {
    border-top: 1px solid var(--green);
  }
  &:nth-of-type(3),
  &:nth-of-type(6),
  &:nth-of-type(9),
  &:nth-of-type(12),
  &:nth-of-type(15),
  &:nth-of-type(18),
  &:nth-of-type(21),
  &:nth-of-type(24),
  &:nth-of-type(27),
  &:nth-of-type(30) {
    margin-right: 0;
  }
  & > a {
    margin-right: 10px;
    line-height: 0;
    & > div {
      width: 190px;
      height: 190px;
      background-color: var(--mid);
      &.placeholder {
        background: url(${AlserkalImage});
        background-size: 60% 60%;
        background-position: center center;
        background-repeat: no-repeat;
        border: 1px solid var(--black);
      }
      &.venuesplaceholdernw {
        background: url(${AlserkalVenueImage});
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
        border: 1px solid var(--black);
      }
      &.storyplaceholdernw{
        background: url(${AlserkalStoryImage});
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
        border: 1px solid var(--black);
      }
    }
  }
  & > div {
    width: 190px;
    max-height: 190px;
    overflow-y: hidden;
    & h3,
    & h4 {
      margin: 0;
      & a {
        color: var(--text);
      }
    }
    & h3 {
      font-size: 15px;
      line-height: 21px;
      letter-spacing: 0.15px;
    }
    & h4 {
      text-transform: uppercase;
      color: var(--green);
      font-size: 11px;
      line-height: 16.5px;
      letter-spacing: 0.66px;
      font-weight: 500;
      margin-top: 14px;
    }
    & p {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.36px;
    }
  }
  @media (max-width: 1439px) {
    &:first-of-type,
    &:nth-child(2),
    &:nth-child(3) {
      border-top: none;
    }
    &:nth-of-type(3),
    &:nth-of-type(6),
    &:nth-of-type(9),
    &:nth-of-type(12),
    &:nth-of-type(15),
    &:nth-of-type(18),
    &:nth-of-type(21),
    &:nth-of-type(24),
    &:nth-of-type(27),
    &:nth-of-type(30) {
      margin-right: 25px;
    }
    &:first-of-type,
    &:nth-child(2) {
      border-top: 1px solid var(--green);
    }
    &:nth-of-type(2),
    &:nth-of-type(4),
    &:nth-of-type(6),
    &:nth-of-type(8),
    &:nth-of-type(10),
    &:nth-of-type(12),
    &:nth-of-type(14),
    &:nth-of-type(16),
    &:nth-of-type(18),
    &:nth-of-type(20),
    &:nth-of-type(22),
    &:nth-of-type(24),
    &:nth-of-type(26),
    &:nth-of-type(28),
    &:nth-of-type(30),
    &:nth-of-type(32),
    &:nth-of-type(34) {
      margin-right: 0;
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
    margin-right: 0;
    margin-left: auto !important;
    margin-right: auto !important;
    border-top: none !important;
    border-bottom: none !important;
    align-items: center;
    padding-bottom: 0;
    & > a {
      margin-right: 0;
    }
    & > div {
      margin-top: 10px;
      width: initial;
      max-width: 100%;
      & > h3,
      & > h4,
      & > p,
      & > div > p {
        text-align: center;
      }
    }
  }
`;
