import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { H3,H4 } from "./../Typography";
import { getDate, getTime, cleanCraftDate } from "./../../modules/time";
import {
  SearchResultsWrapper,
  ResultGrid,
  ResultCard,
  ResultsBlock,
} from "./elements";

const SearchResults = ({
  wordsResults,
  imagesResults,
  videoResults,
  soundsResults,
  eventsResults,
  communityResults,
  venuesResults,
  ourStoryResults,
}) => {
  const now = new Date();
  const upcomingEvents = eventsResults.filter((x) => {
    const thisDate = new Date(x.eventEndDateTime || x.eventStartDateTime);
    return (thisDate >= now);
  });
  const passedEvents = eventsResults.filter((x) => {
    const thisDate = new Date(x.eventEndDateTime || x.eventStartDateTime);
    return (thisDate < now);
  });

  console.log(wordsResults);

  return (
    <SearchResultsWrapper>
      {upcomingEvents.length ? (
        <ResultsBlock>
          <H3>Upcoming Events</H3>
          <ResultGrid>
            {upcomingEvents.map((result, index) => (
              <ResultCard key={index}>
                <Link to={`/event/${result.slug}/`}>
                {result.listingImage &&
                  result.listingImage.length &&
                  result.listingImage[0].card &&
                  result.listingImage[0].card.childImageSharp ? (
                    <Img
                      fixed={result.listingImage[0].card.childImageSharp.fixed}
                    />
                  ) : (
                    <>
                    {result.headerImage &&
                      result.headerImage.length &&
                      result.headerImage[0].card &&
                      result.headerImage[0].card.childImageSharp ? (
                        <Img
                          fixed={result.headerImage[0].card.childImageSharp.fixed}
                        />
                      ) : (
                        <div className="placeholder" />
                      )}
                    </>
                  )}
                  
                </Link>
                <div>
                  <h3>
                    <Link to={`/event/${result.slug}/`}>{result.title}</Link>
                  </h3>
                  {result.eventLocation &&
                  result.eventLocation.length &&
                  result.eventLocation[0].title ? (
                    <h4>{result.eventLocation[0].title}</h4>
                  ) : null}
                  <p>{result.eventShortDescription || result.subtitle}</p>
                </div>
              </ResultCard>
            ))}
          </ResultGrid>
        </ResultsBlock>
      ) : null}
      {passedEvents.length ? (
        <ResultsBlock>
          <H3>Past Events</H3>
          <ResultGrid>
            {passedEvents.map((result, index) => (
              <ResultCard key={index}>
                <Link to={`/event/${result.slug}/`}>
                {result.listingImage &&
                  result.listingImage.length &&
                  result.listingImage[0].card &&
                  result.listingImage[0].card.childImageSharp ? (
                    <Img
                      fixed={result.listingImage[0].card.childImageSharp.fixed}
                    />
                  ) : (
                    <>
                    {result.headerImage &&
                      result.headerImage.length &&
                      result.headerImage[0].card &&
                      result.headerImage[0].card.childImageSharp ? (
                        <Img
                          fixed={result.headerImage[0].card.childImageSharp.fixed}
                        />
                      ) : (
                        <div className="placeholder" />
                      )}
                    </>
                  )}
                </Link>
                <div>
                  <h3>
                    <Link to={`/event/${result.slug}/`}>{result.title}</Link>
                  </h3>
                  {result.eventLocation &&
                  result.eventLocation.length &&
                  result.eventLocation[0].title ? (
                    <h4>{result.eventLocation[0].title}</h4>
                  ) : null}
                  <p>{result.eventShortDescription || result.subtitle}</p>
                </div>
              </ResultCard>
            ))}
          </ResultGrid>
        </ResultsBlock>
      ) : null}
      {communityResults.length ? (
        <ResultsBlock>
          <H3>Community</H3>
          <ResultGrid>
            {communityResults.map((result, index) => (
              <ResultCard key={index}>
                <Link to={`/community/${result.communityMemberSlug}/`}>
                  {result.headerImage &&
                  result.headerImage.length &&
                  result.headerImage[0].card &&
                  result.headerImage[0].card.childImageSharp ? (
                    <Img
                      fixed={result.headerImage[0].card.childImageSharp.fixed}
                    />
                  ) : (
                    <div className="placeholder" />
                  )}
                </Link>
                <div>
                  <h3>
                    <Link to={`/community/${result.communityMemberSlug}/`}>
                      {result.title}
                    </Link>
                  </h3>
                  <h4>{result.communityMemberLocation}</h4>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: result.communityMemberDescription,
                    }}
                  />
                </div>
              </ResultCard>
            ))}
          </ResultGrid>
        </ResultsBlock>
      ) : null}
      {wordsResults.length ? (
        <ResultsBlock>
          <H3>Words</H3>
          <ResultGrid>
            {wordsResults.map((result, index) => 
            (
              <ResultCard key={index}>
                <Link to={`/words/${result.slug}/`}>
                  {result.headerImage &&
                  result.headerImage.length &&
                  result.headerImage[0].card &&
                  result.headerImage[0].card.childImageSharp ? (
                    <Img
                      fixed={result.headerImage[0].card.childImageSharp.fixed}
                    />
                  ) : (
                    <div className="placeholder" />
                  )}
                </Link>
                <div>
                  <h3>
                    <Link to={`/words/${result.slug}/`}>{result.title}</Link>
                  </h3>
                  <h4>{result.editorialAuthor}</h4> 
                  {(() => {
                  if (result.tags.length) {
                    let t='';
                    if(result.tags && result.tags.length>0) 
                    {
                      result.tags.map((y) => {
                        if(t.length>0)
                        {
                          t=t+', '+y.title
                        }
                        else{
                          t=y.title
                        }
                        
                      })
                    }
                    return (
                      <span style={{fontSize:'12px',lineHeight:'16.5px',letterSpacing: '0.66px',
                      fontWeight: '400',
                      marginTop: '14px',fontStyle:'italic'}}>{t}</span>
                    )
                  } else {
                    null
                  }
                  
                  })()}
                  <p>{result.editorialShortDescription}</p>
                </div>
              </ResultCard>
            ))}
          </ResultGrid>
        </ResultsBlock>
      ) : null}
      {imagesResults.length ? (
        <ResultsBlock>
          <H3>Images</H3>
          <ResultGrid>
            {imagesResults.map((result, index) => (
              <ResultCard key={index}>
                <Link to={`/images/${result.slug}/`}>
                  {result.headerImage &&
                  result.headerImage.length &&
                  result.headerImage[0].card &&
                  result.headerImage[0].card.childImageSharp ? (
                    <Img
                      fixed={result.headerImage[0].card.childImageSharp.fixed}
                    />
                  ) : (
                    <div className="placeholder" />
                  )}
                </Link>
                <div>
                  <h3>
                    <Link to={`/images/${result.slug}/`}>{result.title}</Link>
                  </h3>
                  <h4>{result.editorialAuthor}</h4>
                  {(() => {
                  if (result.tags.length) {
                    let t='';
                    if(result.tags && result.tags.length>0)
                    {
                      result.tags.map((y) => {
                        if(t.length>0)
                        {
                          t=t+', '+y.title
                        }
                        else{
                          t=y.title
                        }
                        
                      })
                    }
                    return (
                      <span style={{fontSize:'12px',lineHeight:'16.5px',letterSpacing: '0.66px',
                      fontWeight: '400',
                      marginTop: '14px',fontStyle:'italic'}}>{t}</span>
                    )
                  } else {
                    null
                  }
                  
                  })()}
                  <p>{result.editorialShortDescription}</p>
                </div>
              </ResultCard>
            ))}
          </ResultGrid>
        </ResultsBlock>
      ) : null}
      {videoResults.length ? (
        <ResultsBlock>
          <H3>Video</H3>
          <ResultGrid>
            {videoResults.map((result, index) => (
              <ResultCard key={index}>
                <Link to={`/videos/${result.slug}/`}>
                  {result.headerImage &&
                  result.headerImage.length &&
                  result.headerImage[0].card &&
                  result.headerImage[0].card.childImageSharp ? (
                    <Img
                      fixed={result.headerImage[0].card.childImageSharp.fixed}
                    />
                  ) : (
                    <div className="placeholder" />
                  )}
                </Link>
                <div>
                  <h3>
                    <Link to={`/video/${result.slug}/`}>{result.title}</Link>
                  </h3>
                  <h4>{result.editorialAuthor}</h4>
                  {(() => {
                  if (result.tags.length) {
                    let t='';
                    if(result.tags && result.tags.length>0)
                    {
                      result.tags.map((y) => {
                        if(t.length>0)
                        {
                          t=t+', '+y.title
                        }
                        else{
                          t=y.title
                        }
                        
                      })
                    }
                    return (
                      <span style={{fontSize:'12px',lineHeight:'16.5px',letterSpacing: '0.66px',
                      fontWeight: '400',
                      marginTop: '14px',fontStyle:'italic'}}>{t}</span>
                    )
                  } else {
                    null
                  }
                  
                  })()}
                  <p>{result.editorialShortDescription}</p>
                </div>
              </ResultCard>
            ))}
          </ResultGrid>
        </ResultsBlock>
      ) : null}
      {soundsResults.length ? (
        <ResultsBlock>
          <H3>Sounds</H3>
          <ResultGrid>
            {soundsResults.map((result, index) => (
              <ResultCard key={index}>
                <Link to={`/sounds/${result.slug}/`}>
                  {result.headerImage &&
                  result.headerImage.length &&
                  result.headerImage[0].card &&
                  result.headerImage[0].card.childImageSharp ? (
                    <Img
                      fixed={result.headerImage[0].card.childImageSharp.fixed}
                    />
                  ) : (
                    <div className="placeholder" />
                  )}
                </Link>
                <div>
                  <h3>
                    <Link to={`/sounds/${result.slug}/`}>{result.title}</Link>
                  </h3>
                  <h4>{result.subtitle}</h4>
                  {(() => {
                  if (result.tags.length) {
                    let t='';
                    if(result.tags && result.tags.length>0)
                    {
                      result.tags.map((y) => {
                        if(t.length>0)
                        {
                          t=t+', '+y.title
                        }
                        else{
                          t=y.title
                        }
                        
                      })
                    }
                    return (
                      <span style={{fontSize:'12px',lineHeight:'16.5px',letterSpacing: '0.66px',
                      fontWeight: '400',
                      marginTop: '14px',fontStyle:'italic'}}>{t}</span>
                    )
                  } else {
                    null
                  }
                  
                  })()}
                  <p>{result.editorialShortDescription}</p>
                </div>
              </ResultCard>
            ))}
          </ResultGrid>
        </ResultsBlock>
      ) : null}
      {venuesResults.length ? (
        <ResultsBlock>
          <H3>Venues</H3>
          <ResultGrid>
            {venuesResults.map((result, index) => (
              <ResultCard key={index}>
                <Link to={`/venue/${result.slug}/`}>
                  <div className="placeholder venuesplaceholdernw" />
                </Link>
                <div>
                  <h3>
                    <Link to={`/venue/${result.slug}/`}>{result.title}</Link>
                  </h3>
                  <p>{result.subtitle}</p>
                </div>
              </ResultCard>
            ))}
          </ResultGrid>
        </ResultsBlock>
      ) : null}
      {ourStoryResults.length ? (
        <ResultsBlock>
          <H3>Our story</H3>
          <ResultGrid>
            <ResultCard>
              <Link to={`/our-story/`}>
                <div className="placeholder storyplaceholdernw" />
              </Link>
              <div>
                <h3>
                  <Link to={`/our-story/`}>Our story</Link>
                </h3>
                <p>
                  A socially responsible and forward-thinking cultural
                  enterprise, Alserkal is dedicated to developing sustainable
                  models for homegrown initiatives, while supporting narratives
                  and research from the Global South.
                </p>
              </div>
            </ResultCard>
          </ResultGrid>
        </ResultsBlock>
      ) : null}
    </SearchResultsWrapper>
  );
};

export default SearchResults;
