import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "./../components/Layout";
import SearchField from "./../components/SearchField";
import SearchResults from "./../components/SearchResults";
import { SearchMainWrapper } from "./../components/SearchField/elements";
// TODO: rich text
// TODO: dealing with spaces / multiple words

const getEntryFromId = (list, id) =>
  list.filter((x) => x.id === id).length
    ? list.filter((x) => x.id === id)[0]
    : [];

const SearchPage = () => {
  const [searchFor, setSearchFor] = React.useState("");
  const [wordsResults, setWordsResults] = React.useState([]);
  const [imagesResults, setImagesResults] = React.useState([]);
  const [videoResults, setVideoResults] = React.useState([]);
  const [soundsResults, setSoundsResults] = React.useState([]);
  const [eventsResults, setEventsResults] = React.useState([]);
  const [communityResults, setCommunityResults] = React.useState([]);
  const [venuesResults, setVenuesResults] = React.useState([]);
  const [ourStoryResults, setOurStoryResults] = React.useState([]);
  const searchQuery = useStaticQuery(graphql`
    query {
      words: allCraftArticlesWordsEntry(filter: { isStaging: { eq: false } }) {
        nodes {
          id
          slug
          title
          editorialAuthor
          editorialType
          editorialShortDescription
          tags
          {
            title
            slug
          }
          headerImage {
            ... on Craft_editorialAssets_Asset {
              card: localFile {
                childImageSharp {
                  fixed(height: 190, width: 190, fit: COVER) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
      images: allCraftArticlesImagesEntry(
        filter: { isStaging: { eq: false } }
      ) {
        nodes {
          id
          slug
          title
          subtitle
          tags
          {
            title
            slug
          }
          editorialAuthor
          editorialPhotoEssayAuthor
          editorialType
          editorialShortDescription
          headerImage {
            ... on Craft_editorialAssets_Asset {
              card: localFile {
                childImageSharp {
                  fixed(height: 190, width: 190, fit: COVER) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
      video: allCraftArticlesVideosEntry(filter: { isStaging: { eq: false } }) {
        nodes {
          id
          slug
          title
          editorialAuthor
          editorialType
          tags
          {
            title
            slug
          }
          editorialShortDescription
          headerImage {
            ... on Craft_editorialAssets_Asset {
              card: localFile {
                childImageSharp {
                  fixed(height: 190, width: 190, fit: COVER) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
      sounds: allCraftArticlesSoundsEntry(
        filter: { isStaging: { eq: false } }
      ) {
        nodes {
          id
          slug
          title
          editorialShortDescription
          editorialType
          subtitle
          tags
          {
            title
            slug
          }
          headerImage {
            ... on Craft_editorialAssets_Asset {
              card: localFile {
                childImageSharp {
                  fixed(height: 190, width: 190, fit: COVER) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
      events: allCraftEventEventEntry(filter: { isStaging: { eq: false } }) {
        nodes {
          id
          slug
          title
          subtitle
          eventStartDateTime
          eventEndDateTime
          eventShortDescription
          eventLocation {
            title
          }
          eventType
          headerImage { 
            ... on Craft_editorialAssets_Asset {
              card: localFile {
                childImageSharp {
                  fixed(height: 190, width: 190, fit: COVER) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          listingImage { 
            ... on Craft_communityAssets_Asset {
              card: localFile {
                childImageSharp {
                  fixed(height: 190, width: 190, fit: COVER) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
      community: allCraftCommunityCommunityMemberEntry {
        nodes {
          id
          title
          communityMemberType
          communityMemberSlug
          communityMemberDescription
          communityMemberLocation
          headerImage {
            ... on Craft_editorialAssets_Asset {
              card: localFile {
                childImageSharp {
                  fixed(height: 190, width: 190, fit: COVER) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
      venues: allCraftVenuesVenuesEntry {
        nodes {
          id
          slug
          title
          subtitle
        }
      }
      ourStory: craftOurStoryOurStoryEntry {
        richContent {
          ... on Craft_richContent_richText_BlockType {
            richText
          }
        }
      }
    }
  `);
  const editorialWords = searchQuery.words.nodes;
  //console.log(editorialWords);
  const editorialImages = searchQuery.images.nodes;
  const editorialVideo = searchQuery.video.nodes;
  const editorialSounds = searchQuery.sounds.nodes;
  const community = searchQuery.community.nodes;
  const events = searchQuery.events.nodes;
  const venues = searchQuery.venues.nodes;
  const ourStory = searchQuery.ourStory.richContent[0].richText;
  //console.log(events)

  const editorialWordsCorpus=  editorialWords.map((x) => {
    let t='';
    if(x.tags && x.tags.length>0)
    {
      x.tags.map((y) => {
        t=t+','+y.title.toLowerCase()
      })
    }
    return {
      id: x.id,
      corpus: `${x.title.toLowerCase()} | ${
        x.editorialAuthor ? x.editorialAuthor.toLowerCase() : ""
      } | ${
        x.editorialShortDescription
          ? x.editorialShortDescription.toLowerCase()
          : ""
      } | ${x.editorialType ? x.editorialType.join(" ").toLowerCase() : ""}`,
      tags : t
    };
  });

  
 
  
    // return {
    //   id: x.id,
    //   corpus: `${x.title.toLowerCase()} | ${
    //     x.editorialAuthor ? x.editorialAuthor.toLowerCase() : ""
    //   } | ${
    //     x.editorialShortDescription
    //       ? x.editorialShortDescription.toLowerCase()
    //       : ""
    //   } | ${x.editorialType ? x.editorialType.join(" ").toLowerCase() : ""}`,
    // };
   // console.log(editorialWordsCorpusTags);


  const editorialImagesCorpus = editorialImages.map((x) => {
    let t='';
    if(x.tags && x.tags.length>0) 
    {
      x.tags.map((y) => {
        t=t+','+y.title.toLowerCase() 
      })
    }
    return {
      id: x.id,
      corpus: `${x.title.toLowerCase()} | ${
        x.editorialAuthor ? x.editorialAuthor.toLowerCase() : ""
      } | ${
        x.editorialPhotoEssayAuthor
          ? x.editorialPhotoEssayAuthor.toLowerCase()
          : ""
      } | ${
        x.editorialShortDescription
          ? x.editorialShortDescription.toLowerCase()
          : ""
      } | ${x.subtitle ? x.subtitle.toLowerCase() : ""} | ${
        x.editorialType ? x.editorialType.join(" ").toLowerCase() : ""
      }`,
      tags : t
    };
  });
  const editorialVideoCorpus = editorialVideo.map((x) => {
    let t='';

    if(x.tags && x.tags.length>0)
    {
      x.tags.map((y) => {
        t=t+','+y.title.toLowerCase()
      })
    }
    return {
      id: x.id,
      corpus: `${x.title.toLowerCase()} | ${
        x.editorialAuthor ? x.editorialAuthor.toLowerCase() : ""
      } | ${
        x.editorialShortDescription
          ? x.editorialShortDescription.toLowerCase()
          : ""
      } | ${x.editorialType ? x.editorialType.join(" ").toLowerCase() : ""}`,
      tags : t
    };
  });
  const editorialSoundsCorpus = editorialSounds.map((x) => {
    let t='';
    if(x.tags && x.tags.length>0)
    {
      x.tags.map((y) => {
        t=t+','+y.title.toLowerCase()
      })
    }
    return {
      id: x.id,
      corpus: `${x.title.toLowerCase()} | ${
        x.subtitle ? x.subtitle.toLowerCase() : ""
      } | ${
        x.editorialShortDescription
          ? x.editorialShortDescription.toLowerCase()
          : ""
      } | ${x.editorialType ? x.editorialType.join(" ").toLowerCase() : ""}`,
      tags : t
    };
  });

  const venuesCorpus = venues.map((x) => {
    return {
      id: x.id,
      corpus: `${x.title.toLowerCase()} || ${
        x.subtitle ? x.subtitle.toLowerCase() : ""
      }`,
    };
  });

  //console.log(editorialVideoCorpus);
  // TODO: need to convert eventType value to title

  const eventsCorpus = events.map((x) => {
    return {
      id: x.id,
      corpus: `${x.title.toLowerCase()} | ${
        x.eventShortDescription ? x.eventShortDescription.toLowerCase() : ""
      } | ${x.subtitle ? x.subtitle.toLowerCase() : ""} | ${x.eventType
        .join(" ")
        .toLowerCase()} | ${x.eventLocation
        .map((y) => y.title)
        .join(" ")
        .toLowerCase()}`,
      startTime:x.eventStartDateTime,
      endTime:x.eventEndDateTime,
    };
  });
  
  // TODO: need to convert communitymemberType value to title

  const communityCorpus = community.map((x) => {
    return {
      id: x.id,
      corpus: `${x.title.toLowerCase()} | ${x.communityMemberLocation.toLowerCase()} | ${x.communityMemberType
        .join(" ")
        .toLowerCase()} | ${
        x.communityMemberDescription
          ? x.communityMemberDescription.toLowerCase()
          : ""
      }`,
    };
  });

  React.useEffect(() => {
    // if we are here, they are looking for something – query the corpuses
    // console.log("in searchfor loop!", searchFor);
    if (searchFor.length > 2) {
      const wordsRawResults = editorialWordsCorpus.filter(
        (x) => (x.corpus.indexOf(searchFor.toLowerCase()) > -1 || x.tags.indexOf(searchFor.toLowerCase()) > -1)
        
      );
      const imagesRawResults = editorialImagesCorpus.filter(
        (x) => (x.corpus.indexOf(searchFor.toLowerCase()) > -1 || x.tags.indexOf(searchFor.toLowerCase()) > -1)
      );
      const videoRawResults = editorialVideoCorpus.filter(
        (x) => (x.corpus.indexOf(searchFor.toLowerCase()) > -1 || x.tags.indexOf(searchFor.toLowerCase()) > -1)
      );
      const soundsRawResults = editorialSoundsCorpus.filter(
        (x) => (x.corpus.indexOf(searchFor.toLowerCase()) > -1 || x.tags.indexOf(searchFor.toLowerCase()) > -1)
      );
      const eventsRawResults = eventsCorpus.filter(
        (x) => x.corpus.indexOf(searchFor.toLowerCase()) > -1
      );
      const communityRawResults = communityCorpus.filter(
        (x) => x.corpus.indexOf(searchFor.toLowerCase()) > -1
      );
      const venuesRawResults = venuesCorpus.filter(
        (x) => x.corpus.indexOf(searchFor.toLowerCase()) > -1
      );
     
      // take this and turn it into something better.
      setWordsResults(
        wordsRawResults.map((x) => getEntryFromId(editorialWords, x.id))
      );
      setImagesResults(
        imagesRawResults.map((x) => getEntryFromId(editorialImages, x.id))
      );
      setVideoResults(
        videoRawResults.map((x) => getEntryFromId(editorialVideo, x.id))
      );
      setSoundsResults(
        soundsRawResults.map((x) => getEntryFromId(editorialSounds, x.id))
      );
      setEventsResults(
        eventsRawResults.map((x) => getEntryFromId(events, x.id))
      );
      setCommunityResults(
        communityRawResults.map((x) => getEntryFromId(community, x.id))
      );
      setVenuesResults(
        venuesRawResults.map((x) => getEntryFromId(venues, x.id))
      );
      setOurStoryResults(
        ourStory.toLowerCase().indexOf(searchFor.toLowerCase()) > -1
          ? [true]
          : []
      );
    } else {
      setWordsResults([]);
      setImagesResults([]);
      setVideoResults([]);
      setSoundsResults([]);
      setEventsResults([]);
      setCommunityResults([]);
      setVenuesResults([]);
      setOurStoryResults([]);
    }
  }, [searchFor]);
  // console.log(eventsResults);
  return (
    <Layout
      skipHeader
      headerBlack
      headerColor={"var(--black)"}
      title={"Alserkal: Search"}
      backgroundColor={"var(--white)"}
    >
      <SearchMainWrapper>
        <SearchField
          searching={setSearchFor}
          totalResults={
            wordsResults.length +
            videoResults.length +
            imagesResults.length +
            soundsResults.length +
            eventsResults.length +
            communityResults.length +
            venuesResults.length +
            ourStoryResults.length
          }
          totalCategories={
            (wordsResults.length > 0) +
            (videoResults.length > 0) +
            (imagesResults.length > 0) +
            (soundsResults.length > 0) +
            (eventsResults.length > 0) +
            (communityResults.length > 0) +
            (venuesResults.length > 0) +
            (ourStoryResults.length > 0)
          }
        />
        <SearchResults
          wordsResults={wordsResults}
          imagesResults={imagesResults}
          videoResults={videoResults}
          soundsResults={soundsResults}
          eventsResults={eventsResults}
          communityResults={communityResults}
          venuesResults={venuesResults}
          ourStoryResults={ourStoryResults}
          key={String(wordsResults)} 
        />
      </SearchMainWrapper>
    </Layout>
  );
};

export default SearchPage;
